<template>
  <div class="framePlan">
    <ViewPlan :types="0" class="h"></ViewPlan>
  </div>
</template>

<script>
export default {
  name: "framePlan",
  components: {
    ViewPlan: () => import('@/views/expertClinic/viewPlan')
  }
}
</script>

<style scoped>
  .framePlan {
    width: 100vw;
    height: 87vh;
    overflow-y: auto;
  }
  .framePlan::-webkit-scrollbar { width: 0 !important }
  .h {padding-bottom: 3vh;}
</style>